import { render, staticRenderFns } from "./productdetail7.vue?vue&type=template&id=2135b5fc&scoped=true&"
import script from "./productdetail.ts?vue&type=script&lang=ts&"
export * from "./productdetail.ts?vue&type=script&lang=ts&"
import style0 from "./productdetail7.vue?vue&type=style&index=0&id=2135b5fc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2135b5fc",
  null
  
)

export default component.exports